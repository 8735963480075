import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActionArea,
} from "@mui/material";
import SavingsIcon from "@mui/icons-material/Savings";
import HomeIcon from "@mui/icons-material/Home";

import { customPalette } from "../../../../App";

export default function WelcomeSectionContent() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        maxWidth: "95vw",
        paddingTop: "50px",
      }}
    >
      {/* Start visualising you financial future and stay motivated to reach your
        goals. Take control of your finances with our personal finance
        calculator tools. */}
      <Typography variant="h1" fontSize="2rem">
        Personal Finance Calculator Tools to Help You Achieve Your Financial
        Goals.
      </Typography>

      <Card
        sx={{
          minWidth: 275,
          borderRadius: "8px",
          backgroundColor: customPalette.background.dark,
          color: customPalette.text.secondary,
          textDecoration: "none",
        }}
        component="a"
        href="#savings_calculator"
      >
        <CardActionArea>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <SavingsIcon
                sx={{
                  fontSize: "110px",
                  color: "pink",
                }}
              />{" "}
              <Typography variant="body1">
                {" "}
                It all starts with savings. Start saving or stay motivated with
                our savings growth visualisator! 📊
              </Typography>
            </Box>

            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                color: customPalette.background.blue,
                fontWeight: "bold",
              }}
            >
              SAVINGS CALCULATOR
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>

      <Card
        sx={{
          minWidth: 275,
          borderRadius: "8px",
          backgroundColor: customPalette.background.lightgrey,
          color: customPalette.text.primary,
          textDecoration: "none",
        }}
        component="a"
        href="#mortgage_calculator"
      >
        <CardActionArea>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <Typography variant="body1">
                {" "}
                Could you repay your mortgage faster? Our tool helps you plan your journey to becoming mortgage-free. 🏠
              </Typography>
              <HomeIcon
                sx={{
                  fontSize: "110px",
                  color: customPalette.background.dark,
                }}
              />
            </Box>

            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                color: customPalette.background.blue,
                fontWeight: "bold",
              }}
            >
              MORTGAGE CALCULATOR
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}
