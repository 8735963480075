import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Section } from "../pages/home/HomePage";
import { snakeCaseToTitle } from "../../helpers/formatters";

interface FooterProps {
  sections: Section[];
}

const contactOptions = [
  {
    id: "email",
    href: "mailto:hello@mymoneystash.com",
    icon: MailIcon,
    text: "Email",
  },
  {
    id: "linkedin",
    href: "https://www.linkedin.com/",
    icon: LinkedInIcon,
    text: "LinkedIn",
  },
];


export function Footer({ sections }: FooterProps) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "24px",
          padding: "40px 80px",
        }}
      >
        <Box sx={{ maxWidth: "300px" }}>
          <Typography variant="body1">Contact Us</Typography>
          <List>
            {contactOptions.map((item) => (
              <ListItem disablePadding key={item.id}>
                <ListItemButton
                  target="_blank"
                  component="a"
                  href={item.href}
                  sx={{
                    ":hover": {
                      backgroundColor: "inherit",
                      textDecoration: "underline",
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "inherit" }}>
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ fontSize: "0.8rem", fontStyle: "underline" }} // body1 is 1rem
                    primary={item.text}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ maxWidth: "300px" }}>
          <Typography variant="body1">Site Map</Typography>
          <List>
            {sections.map((item) => (
              <ListItem disablePadding key={item.id}>
                <ListItemButton
                  sx={{
                    ":hover": {
                      backgroundColor: "inherit",
                      textDecoration: "underline",
                    },
                  }}
                  component="a"
                  href={`#${item.id}`}
                >
                  <ListItemText
                    sx={{ fontSize: "0.8rem", textDecoration: "underline" }} // body1 is 1rem
                    primary={snakeCaseToTitle(item.id)} // overwrite home section title for the menu.
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <Divider />
      <Typography
        variant="body1"
        sx={{ textAlign: "center", padding: "8px 0" }}
      >
        ✌️ Made with love ❤️
      </Typography>
    </Box>
  );
}
