import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { customPalette } from "../../App";
import Box from "@mui/material/Box";

export default function SignupForm() {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const validateEmail = () => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    setIsValidEmail(isValid);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Mock URL where you can send the email data
      const location = window.location.href;
      const local = location.startsWith("http://localhost:3000/");

      const baseURL = local
        ? "http://localhost:3000"
        : process.env.REACT_APP_API_BASE_URL;

      const referrer = document.referrer;

      const payload = referrer
        ? { Email: email, REFERRER_NAME: referrer }
        : { Email: email };

      await fetch(`${baseURL}/signup`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Reset the email input and display a success message
      setEmail("");
      setMessage("Thank you! Email submitted successfully.");
    } catch (error) {
      // Handle errors here and display an error message
      setMessage("Error submitting email. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        paddingBottom: "18px",
      }}
    >
      {" "}
      {/* <InputLabel htmlFor="subscribe-email">Email: </InputLabel> */}
      <TextField
        id="subscribe-email"
        // label="Email"
        placeholder="Enter your email"
        size="medium"
        sx={{
          backgroundColor: customPalette.background.white,
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              // "&.Mui-focused fieldset" hides the value inside the input behind the backgroundColor
              backgroundColor: "common.white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "inherit", // TODO UPdate no border color change when focused
            },
          },
        }}
        InputProps={{
          sx: {
            borderRadius: "8px",
          },
        }} // Needed for the outline border to have same radius as TextField
        variant="outlined"
        type="email"
        fullWidth
        value={email}
        onChange={handleEmailChange}
        required
        //validation:
        onBlur={() => validateEmail()}
        error={!isValidEmail}
        helperText={!isValidEmail && "Please enter a valid email address."}
      />
      <Button
        variant="contained"
        sx={{
          backgroundColor: customPalette.background.dark,
          "&:hover": { backgroundColor: customPalette.background.dark },
        }}
        // color={customPalette.background.dark}
        type="submit"
        onClick={handleSubmit}
        style={{ width: "100%" }}
      >
        Sign up
      </Button>
      {message && <p>{message}</p>}
    </Box>
  );
}
