import { Box, Typography } from "@mui/material";

interface SectionWrapperProps {
  sectionId: string;
  backgroundColor: string;
  textColor: string;
  sectionTitle?: string;
  sectionDesc?: string;
  children: React.ReactNode;
}

export default function SectionWrapper(props: SectionWrapperProps) {
  return (
    <Box
      component={"section"}
      id={props.sectionId}
      sx={{
        minHeight: props.sectionId === "signup" ? "inherit" : "105vh",
        backgroundColor: props.backgroundColor,
        color: props.textColor,
        paddingX: "10px",
        maxWidth: "100%", // Avoid the whole page scrolling left and right on mobile browser
      }}
    >
      <Box
      id="section_title_desc"
        sx={{
          paddingTop:"18px",
          paddingBottom:"14px"
        }}>
        {props.sectionTitle && (
          <Typography
            variant="h2"
            fontSize="1.75rem"
          >
            {props.sectionTitle}
          </Typography>
        )}

        {props.sectionDesc && (
          <Typography variant="body1">{props.sectionDesc}</Typography>
        )}
      </Box>

      {props.children}
    </Box>
  );
}
