import { customPalette } from "../../../App";
import SignupForm from "../../base/SignupForm";

import {
  SectionWrapper,
  AboutSectionContent,
  WelcomeSectionContent,
  SavingsCalculator,
  MortgageReduction,
} from "./sections";
import { Box } from "@mui/material";

export type Section = {
  id: string; //Snake case, the id is used in the footer's site map and in the menu items
  contentComponent: JSX.Element;
  title?: string;
  desc?: string;
};
// The HomePage know which section and component to render and in which order.
export const homePageSections: Section[] = [
  {
    // title: "Welcome to MyMoneyStash!",
    id: "home",
    contentComponent: <WelcomeSectionContent />,
  },
  {
    title: "Savings Calculator",
    id: "savings_calculator",
    contentComponent: <SavingsCalculator />,
  },
  {
    title: "Mortgage Calculator",
    desc: "Figure out your monthly mortgage payments based on your desired payoff date.",
    id: "mortgage_calculator",
    contentComponent: <MortgageReduction />,
  },
  {
    // title: "About",
    id: "about",
    contentComponent: <AboutSectionContent />,
  },

  {
    title: "Get the latest from My Money Stash.",
    desc: "Sign up to receive new feature updates.",
    id: "signup",
    contentComponent: <SignupForm />,
  },
];

export default function HomePage() {
  return (
    <>
      {homePageSections.map((section, idx) => {
        const isEven = idx % 2 === 0;

        return (
          <SectionWrapper
            key={section.id}
            sectionId={section.id}
            backgroundColor={
              isEven
                ? section.id === "home" || section.id === "signup" // Custom styling for the welcome and signup sections. For other sections we alternate lightgrey and dark background colors.
                  ? customPalette.background.blue
                  : customPalette.background.lightgrey
                : customPalette.background.dark
            }
            textColor={
              isEven
                ? section.id === "home" || section.id === "signup"
                  ? customPalette.text.secondary
                  : customPalette.text.primary
                : customPalette.text.secondary
            }
            sectionTitle={section.title}
            sectionDesc={section.desc}
          >
            <Box id="section_component" sx={{ minHeight: "inherit" }}>
              {section.contentComponent}
            </Box>
          </SectionWrapper>
        );
      })}
    </>
  );
}
